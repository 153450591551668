.mobile-certificates-card{
    background: rgb(248, 248, 248);
    justify-content: center;
    width: 80vw;
    padding: 25px;
    list-style: none;
    border-radius: 5px;
    box-shadow: 5px 5px 8px rgba(17, 17, 17, 0.137);
    margin-top: 5% !important;  
}
.certificates-delete-btn :hover {
    color: red;
}
.certificates-loading-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 50px;
    width: 500px;
    padding: 25px;
}

