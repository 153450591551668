.mobile-sidenav {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ffffff;
  border-right: 1px solid black;
  overflow-x: hidden;
  padding-top: 60px;
}

.miniSidenav {
  height: 76px;
  width: 100px;
  position: fixed;
  z-index: 1;
  bottom: 20px;
  left: 20px;
  background-color: lightgray;
  border-right: 1px solid black;
  overflow-x: hidden;
  text-align: center;
  cursor: pointer;
}

center#minimize {
  font-size: 50px;
}

.mobile-miniside{
  display: grid;
  grid-template-columns: 5% 15% 60% auto;
  background-color: #ebebeb;
  width: 95%;
  height: 10vh;
  border-top: 1px solid rgb(189, 189, 189);
  border-right: 1px solid rgb(189, 189, 189);
  border-bottom: 1px solid rgb(189, 189, 189);
  border-left: 5px solid yellowgreen;
  padding: 10px 5px 10px 15px;
  margin: 10px;
  font-size: larger;
  cursor: pointer;
  align-items: center;
}

.mobile-signside{
  display: grid;
  grid-template-columns: 5% 75% auto;
  background-color: #ebebeb;
  width: 95%;
  height: 10vh;
  border-top: 1px solid rgb(189, 189, 189);
  border-right: 1px solid rgb(189, 189, 189);
  border-bottom: 1px solid rgb(189, 189, 189);
  border-left: 5px solid yellowgreen;
  -webkit-box-pack: center;
  padding: 10px 5px 10px 15px;
  margin: 10px;
  font-size: larger;
  cursor: pointer;
  align-items: center;
}

#mobile-stampside{
  display: grid;
  grid-template-columns: 5% 75% auto;
  background-color: #ebebeb;
  width: 95%;
  height: 10vh;
  border-top: 1px solid rgb(189, 189, 189);
  border-right: 1px solid rgb(189, 189, 189);
  border-bottom: 1px solid rgb(189, 189, 189);
  border-left: 5px solid yellowgreen;
  -webkit-box-pack: center;
  padding: 10px 5px 10px 15px;
  margin: 10px;
  font-size: larger;
  cursor: pointer;
  align-items: center;
}

.minigrid{
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
}

.mobile-miniside-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 10vh;
  padding: 10px 5px;
}

.mobile-export {
  width: 45%;
  height: auto;
  padding: 10px;
  margin: 10px;
  font-size: larger;
  cursor: pointer;
  align-items: center;
}

.mobile-sidenav-hide {
  width: 45%;
  height: auto;
  padding: 10px;
  margin: 10px;
  font-size: larger;
  cursor: pointer;
  align-items: center;
}

.mobile-sidenav-show {
  display: flex;
  width: 75px;
  height: 75px;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  border: 1px solid black;
  z-index: 1;
}

.add{
  color: gray;
  font-weight: bold;
}

.popupbox{
  position: fixed;
  z-index: 10;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.transparentblur{
  z-index: 9;
  background-color : rgba(0, 0, 0, 0.4); 
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-exportingbg{
  z-index: 9;
  width: 100%;
  height: 100%;
  top: 60px;
  position: absolute;
}

.mobile-largebox{
  display: flex;
  flex-wrap: wrap;
  width: 90vw;
  justify-content: center;
  z-index: 10;
  background-color : white;
  box-sizing: content-box;
  position: relative;
}

.mobile-menu {
  background-color: lightgray;
  width: 90vw;
  height: 10vh;
  display: flex;
}

.menutext{
  text-align: center;
  padding: 20px 100px 20px 100px;
  font-size: larger;
}

.menubutton{
  text-align: center;
  padding: 15px 30px 15px 30px;
  margin: 5px;
  font-size: larger;
  flex-grow: 2;
  cursor: pointer;
  border: 1px solid rgb(110, 110, 110);
}

.break {
  flex-basis: 100%;
  height: 0;
}

.mobile-minibox {
  background-color: #ebebeb;
  width: 20%;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  font-size: larger;
  cursor: pointer;
  z-index: 10;
  border-right: 1px solid black;
  overflow-x: hidden;
  text-align: center;
  cursor: pointer;
  flex: 40%;
}

#mobile-dropimage{
  padding: 5vw;
}

.mobile-drawzone{
  padding: 5vw;
}

.mobile-typebox{
  padding: 5vw;
}

#mobile-signature-pad{
  background-color: lightgray;
  border: 1px solid darkslategrey;
  height: 30vh;
  width: 80vw;
}

.mobile-signature-pad-slider{
  margin: 5px;
}

.mobile-signature-pad-buttons{
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.mobile-signature-pad-button{
  width: 15vw;
  height: 15vw;
}

.colorPicker{
  cursor: pointer;
}

.mobile-textbox-pad-buttons{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-textbox-color-pad{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  justify-self: start;
}

.mobile-textbox-finished-pad-button{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}

.mobile-dropzoneimg {
  display: flex;
  width: 80vw;
  height: 50vh;
  flex-direction: column;
  border: 2px dashed rgb(134, 134, 134);
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: center;
}

.mobile-dropzone-text {
  text-align: center;
  padding: 20px;
}

/* override cropper.css for round crop */
/* .cropper-view-box,
    .cropper-face {
      border-radius: 50%;
} */

#close-stamp-tab{
  text-align: right;
  padding: 10px;
  margin-bottom: -25px;
}

#image{
  display: block;
  max-width: 100%;
}

.crop-menu {
  text-align: right;
  padding-top: 30px;
}

#mobile-apply-crop{
  height: 50px;
  width: 100%;
  font-size: xx-large;
}

#crop-pad{
  padding: 50px;
  max-width: 500px;
}

.container.crop-pad {
  max-width: 100%;
  display: block;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0px;
  overflow: hidden;
}

#mobile-signtextbox{
  display: flex;
  white-space: nowrap;
  width: 80vw;
  height: 30vh;
  margin: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid rgb(134, 134, 134);
  font-size: 50px;
  overflow: hidden;
}

#textboxfont{
  height: 90%;
  font-size: large;
}

#deleteSign{
  display: flex;
  background-color: #ebebeb;
  cursor: pointer;
  align-items: center;
}

#imagediv{
  z-index: 999;
}

.mobile-export-loading-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 120px;
  max-width: calc(100vw - 267px);
}