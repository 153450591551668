.Drop {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 20px;
}

.pdf-padding {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.button-padding {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.head i {
  padding-right: 10px;
}

.head h4 {
  color: gray;
}

.containers#lesspad {
  padding-top: 0px;
  overflow: hidden;
  width: calc(100vw - 267px);
}

.container.pdfscreen {
  position: fixed;
  width: 75%;
  height: 100%;
  right: 0;
}

.container.pdfscreenfull {
  position: fixed;
  width: 75%;
  height: 100%;
  right: 1%;
}

.card {
  background-color: whitesmoke;
  border: 1px solid black;
  overflow: scroll;
  position: relative;
  justify-content: right;
}

#pdfviewer {
  position: relative;
}

.padtop {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  padding-top: 0px;
  margin-top: 25px;
}

.dropzone {
  border: 2px dashed rgb(134, 134, 134);
  height: 350px;
  width: 1000px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: center;
}

.dropzone p {
  font-size: 25px;
  margin-top: 150px;
  color: rgb(151, 151, 151);
  font-weight: 500;
  position: absolute;
}

.dropzone button {
  padding: 10px 25px;
  font-size: 20px;
  font-weight: 500;
  outline: none;
  border-radius: 5px;
  background: #fff;
  color: #000;
}

.up-btn {
  border: 1px solid #c4c4c4;
  background: #c4c4c4;
  color: rgb(53, 52, 107);
  margin-bottom: 90px;
  cursor: pointer;
}

#zoom {
  font-size: medium;
}

#textOptionBox {
  border: 1px solid black;
  background-color: white;
  box-shadow: 1px 1px 5px black;
  padding: 5px;
  position: absolute;
  cursor: pointer;
}

#signOptionBox {
  border: 1px solid black;
  background-color: white;
  box-shadow: 1px 1px 5px black;
  padding: 5px;
  position: absolute;
  cursor: pointer;
}

#colorBox {
  border: 1px solid black;
  background-color: white;
  box-shadow: 1px 1px 5px black;
  padding: 5px;
  position: absolute;
  cursor: pointer;
}

.optionBox {
  position: absolute;
  cursor: pointer;
}

.noselect {
  user-select: none;
}

.exporting-text {
  font-size: xx-large;
  font-weight: bolder;
  color: black;
}

.pointer-cursor {
  cursor: pointer;
}

.columns-list-span {
  display: flex;
  align-items: center;
  height: 100%;
}

.all-page-container {
  background-color: gray;
  min-height: calc(100vh - 130px);
  max-width: calc(100vw - 250px);
}

.dropzone-filter-header {
  width: 10%;
  padding: 5%;
  align-self: center;
}

.dropzone-filter-text {
  width: 90px;
  margin-top: 0;
  align-self: center;
  text-align: right;
}

.dropzone-filter-dropdown {
  width: 70px;
  margin: 0 10px;
  height: 30px;
  border-radius: 6px;
  align-self: center;
}

.dropzone-loading-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 120px;
  max-width: calc(100vw - 267px);
}
