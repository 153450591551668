.Drop {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 20px;
}

.mobile-pdf-padding {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  padding: 5px;
}

.button-padding {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.head i {
  padding-right: 10px;
}

.head h4 {
  color: gray;
}

/* .containers#lesspad {
  display: block;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  overflow: hidden;
} */

.container.pdfscreen {
  position: fixed;
  width: 75%;
  height: 100%;
  right: 0;
}

.container.pdfscreenfull {
  position: fixed;
  width: 75%;
  height: 100%;
  right: 1%;
}

.mobile-card {
  background-color: whitesmoke;
  border: 1px solid black;
  overflow: scroll;
  position: relative;
  justify-content: right;
}

#pdfviewer {
  position: relative;
}

#padtop {
  padding-top: 0px;
  margin-top: 25px;
}

.padtop10 {
  padding-top: 10%;
}

.dropzone {
  border: 2px dashed rgb(134, 134, 134);
  height: 350px;
  width: 1000px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: center;
}

.dropzone p {
  font-size: 25px;
  margin-top: 150px;
  color: rgb(151, 151, 151);
  font-weight: 500;
  position: absolute;
}

.dropzone button {
  padding: 10px 25px;
  font-size: 20px;
  font-weight: 500;
  outline: none;
  border-radius: 5px;
  background: #fff;
  color: #000;
}

.up-btn {
  border: 1px solid #c4c4c4;
  background: #c4c4c4;
  color: rgb(53, 52, 107);
  margin-bottom: 90px;
  cursor: pointer;
}

.mobile-pdf-header-left {
  text-align: left;
  display: flex; 
  align-items: center;
}

.mobile-pdf-header-center {
  text-align: center;
  display: flex; 
  align-items: center;
}

.mobile-pdf-header-right {
  text-align: right;
  display: flex; 
  align-items: center;
}

.mobile-zoom {
  width: 100%;
  border-radius: 5px;
  background-color: white;
  font-size: medium;
}

#textOptionBox {
  border: 1px solid black;
  background-color: white;
  box-shadow: 1px 1px 5px black;
  padding: 5px;
  position: absolute;
  cursor: pointer;
}

#signOptionBox {
  border: 1px solid black;
  background-color: white;
  box-shadow: 1px 1px 5px black;
  padding: 5px;
  position: absolute;
  cursor: pointer;
}

#colorBox {
  border: 1px solid black;
  background-color: white;
  box-shadow: 1px 1px 5px black;
  padding: 5px;
  position: absolute;
  cursor: pointer;
}

.optionBox {
  position: absolute;
  cursor: pointer;
}

.noselect {
  user-select: none;
}

.exporting-text {
  font-size: xx-large;
  font-weight: bolder;
  color: black;
}

.bottom-sheet {
  position: absolute;
  background-color : white;
  bottom: 0;
  left: 0;
  width: 100%;
}

.transparentbg{
  z-index: 9;
  background-color : rgba(0, 0, 0, 0.4); 
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
}

.mobile-all-page-container {
  background-color: gray;
  display: flex;
  justify-content: center;
  align-items: top;
  min-height: calc(100vh);
  max-width: calc(100vw);
}

.mobile-filter-header {
  width: 30vw;
  padding: 5%;
  font-size: larger;
  font-family: 'CSChatThaiUI', sans-serif;
  align-self: center;
}

.mobile-filter-text {
  width: 15vw;
  margin-top: 0;
  margin-right: 2vw;
  font-size: larger;
  font-family: 'CSChatThaiUI', sans-serif;
  align-self: center;
  text-align: right;
}

.mobile-filter-dropdown {
  width: 20vw;
  height: 60%;
  margin-right:  5%;
  border-radius: 10px;
  font-size: larger;
  align-self: center;
}

.mobile-popup {
  background-color: white;
  position: fixed;
  top: 35vh;
  left: 10vw;
  width: 80vw;
  height: 30vh;
  border-radius: 20px;
}

.mobile-popup-close {
  position: relative;
  top: 5%;
  left: 90%;
  width: 5%;
  height: 10%;
}

.mobile-popup-text {
  padding: 5vh 0;
  height: 12vh;
}

.mobile-popup-button {
  width: 100%;
  height: 10.5vh;
  padding-left: 5%;
  padding-right: 5%;
  display: grid;
  grid-template-columns: 50% 50%;
}

.resizable {
  touch-action: none;
}

.drag-drop {
  touch-action: none;
}

.MoreVertical {
  display: flex;
  justify-items: center;
  align-content: center;
}

.mobile-nodata {
  padding-top: 1vh;
  font-size: larger;
}


