*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:'Montserrat', sans-serif;
}

.xlm label{
   border: 2px dashed rgb(194, 194, 194);
    height: 300px;
    width: 700px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
}
.xlm input{
    position: relative;
    padding-top: 50px;
}



  .xlm{
    border: 2px dashed rgb(194, 194, 194);
    height: 300px;
    width: 700px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: center;
  }
  .xlm p{
    font-size: 25px;
    margin-bottom: 10px;
    color: rgb(151, 151, 151);
    font-weight: 500;    
  }
  /* 
  button{
    border: 2px solid #5CDB95;
    color: #5CDB95;
    background-color: #fff;
    padding: 10px 10px;
    font-size: 18px;
    transition: all 0.3s ease;
  }
  button:hover{
    background-color: #5CDB95;
    color: #fff;
  }

 */
