@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@200&display=swap');
/* 
*{
  font-family: 'Prompt', sans-serif;
}
 */
@font-face {
    font-family: 'CSChatThaiUI';
    src: local('CSChatThaiUI'), url(./fonts/CSChatThaiUI.ttf) format('opentype');
}

body {
    /* margin-top: 0; */
    font-family: 'CSChatThaiUI', sans-serif !important;
    /* background-color: green; */
    padding: 0;
    margin: 0;
    /* background-color: '#FAF9F8'; */
    
}
/* 
*{
    font-family: 'CSChatThaiUI', sans-serif !important;
}
*/
.ms-Button-label {
    font-family: 'CSChatThaiUI', sans-serif !important;
    /* font-size: 20px; */
}

span {
    font-family: 'CSChatThaiUI', sans-serif !important;
}

h1 {
    font-family: 'CSChatThaiUI', sans-serif !important;
}

h2 {
    font-family: 'CSChatThaiUI', sans-serif !important;
}

h3 {
    font-family: 'CSChatThaiUI', sans-serif !important;
}

h4 {
    font-family: 'CSChatThaiUI', sans-serif !important;
}

button {
    font-family: 'CSChatThaiUI', sans-serif !important;
}
 
/*
.ms-Nav-linkText {
    font-family: 'CSChatThaiUI', sans-serif !important;
    font-size: 14px;

}

.display{
  display: block;
  align-items: center;
  justify-content: center;
  /* padding-left: 300px; /
  padding-left: 0px;
}

.ms-ScrollablePane {
    background: linear-gradient(90deg,#2e332c,#2e2e2e);


    
}

.ms-Button-flexContainer {
    /* background: linear-gradient(90deg,#2e332c,#2e2e2e); /
    height: 100px;
    /* color: rgb(253, 253, 253); /
    
}


.ms-Button {
    background: linear-gradient(90deg,#2e332c,#2e2e2e);
    height: 100px;
    color: rgb(253, 253, 253);
    
}

.ms-Button a {
    background: rgb(253, 253, 253) !important;
    height: 100px;
    color: rgb(253, 253, 253);
    
}

.ms-Button :hover {
    background: linear-gradient(90deg,#2e332c,#2e2e2e);
    height: 100px;
    color: rgb(253, 253, 253);
    background: #074f97 !important;
    justify-content: left;
    align-items: center;
    display: flex;
    
}

.ms-Button-label {
    font-size: 20px;
    color: rgb(253, 253, 253);
}


 
.ms-Icon {
    font-size: 26px;
    color: rgb(253, 253, 253);
}
 */

@media screen and (max-width: 960px){
  .drop{
    padding-left: 0;
  }
  .display{
    padding-left: 0;
  }
}