.mobile-label {
    font-size: larger;
    margin-top: 2%;
}
.mobile-input {
    width: 100%;
    border: 1px solid;
    border-radius: 10px;
    padding: 2%;
    font-size: larger !important;
}
.mobile-forms {
    padding: 5%;
    margin: 0%;
    width: 100vw;
}
.mobile-divider {
    border: 1px solid gray;
    height: 0;
    width: 100%;
    margin: 5% 0;
}
.mobile-frame {
    border: 1px solid gray;
    padding: 3% 2%;
    display: grid;
    grid-template-columns: 25% 75%;
    user-select: none;
}
.mobile-textarea {
    width: 100%;
    border: 1px solid;
    border-radius: 10px;
    padding: 2%;
    font-size: larger !important;
    resize: none !important;
}
.mobile-icon {
    text-align: center;
    justify-content: center;
}
.mobile-h1 {
    user-select: none;
    vertical-align: baseline;
}
.mobile-h3 {
    overflow: hidden;
}
.mobile-back {
    margin-right: 0.5rem;
    cursor: pointer;
}
.mobile-send-nav {
    display: flex;
    justify-content: space-between;
}
.mobile-send-header {
    display: inline-flex;
    align-items: center;
}
.mobile-send {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: blue;
}
.mobile-send-icon {
    margin-left: 0.5rem;
}
.mobile-upload {
    position: absolute;
    bottom: 5vw;
    right: calc(50vw - 50px);
    z-index: 2;
    background-color: #05386B;
    color: white;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mobile-upload-label {
    font-size: 100px;
}
.mobile-uploadfile {
    display: none;
}