.contact-us {
  display: grid;
  grid-template-areas:
    "head"
    "qr"
    "desc";
  grid-template-rows: 1fr 150px 1fr;
  grid-template-columns: 100%;
  grid-gap: 7.5%;
  margin: 5vh 10vw;
}

.contact-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
}

.contact-header {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: head;
}

.contact-desc {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 1.5;
  grid-area: desc;
}

.contact-office-hrs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.qr {
  border: 1px solid black;
  width: 150px;
  height: 150px;
}

.qr-container {
  display: flex;
  justify-content: center;
  align-items: center;

  grid-area: qr;
}
