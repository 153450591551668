@import url(https://fonts.googleapis.com/css2?family=Prompt:wght@200&display=swap);
/* 

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 */

@font-face {
  font-family: "MilkTea";
  src: local("MilkTea"), url("/static/media/milk tea-normal.95052535.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Akhanake";
  src: local("Akhanake"), url(/static/media/Book_Akhanake.e1bd94a5.ttf) format("truetype");
  font-weight: normal;
}

/* 
*{
  font-family: 'Prompt', sans-serif;
}
 */
@font-face {
    font-family: 'CSChatThaiUI';
    src: local('CSChatThaiUI'), url(/static/media/CSChatThaiUI.2014382b.ttf) format('opentype');
}

body {
    /* margin-top: 0; */
    font-family: 'CSChatThaiUI', sans-serif !important;
    /* background-color: green; */
    padding: 0;
    margin: 0;
    /* background-color: '#FAF9F8'; */
    
}
/* 
*{
    font-family: 'CSChatThaiUI', sans-serif !important;
}
*/
.ms-Button-label {
    font-family: 'CSChatThaiUI', sans-serif !important;
    /* font-size: 20px; */
}

span {
    font-family: 'CSChatThaiUI', sans-serif !important;
}

h1 {
    font-family: 'CSChatThaiUI', sans-serif !important;
}

h2 {
    font-family: 'CSChatThaiUI', sans-serif !important;
}

h3 {
    font-family: 'CSChatThaiUI', sans-serif !important;
}

h4 {
    font-family: 'CSChatThaiUI', sans-serif !important;
}

button {
    font-family: 'CSChatThaiUI', sans-serif !important;
}
 
/*
.ms-Nav-linkText {
    font-family: 'CSChatThaiUI', sans-serif !important;
    font-size: 14px;

}

.display{
  display: block;
  align-items: center;
  justify-content: center;
  /* padding-left: 300px; /
  padding-left: 0px;
}

.ms-ScrollablePane {
    background: linear-gradient(90deg,#2e332c,#2e2e2e);


    
}

.ms-Button-flexContainer {
    /* background: linear-gradient(90deg,#2e332c,#2e2e2e); /
    height: 100px;
    /* color: rgb(253, 253, 253); /
    
}


.ms-Button {
    background: linear-gradient(90deg,#2e332c,#2e2e2e);
    height: 100px;
    color: rgb(253, 253, 253);
    
}

.ms-Button a {
    background: rgb(253, 253, 253) !important;
    height: 100px;
    color: rgb(253, 253, 253);
    
}

.ms-Button :hover {
    background: linear-gradient(90deg,#2e332c,#2e2e2e);
    height: 100px;
    color: rgb(253, 253, 253);
    background: #074f97 !important;
    justify-content: left;
    align-items: center;
    display: flex;
    
}

.ms-Button-label {
    font-size: 20px;
    color: rgb(253, 253, 253);
}


 
.ms-Icon {
    font-size: 26px;
    color: rgb(253, 253, 253);
}
 */

@media screen and (max-width: 960px){
  .drop{
    padding-left: 0;
  }
  .display{
    padding-left: 0;
  }
}
/* 
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@200&display=swap');
*{
  font-family: 'Prompt', sans-serif;
}
 */
.NavbarItem {
  background: linear-gradient(90deg, #05386b 50%, #99f2c8);
  height: 60px;
  width: 100vw;
  display: flex;

  padding-left: 70px;
  padding-right: 90px;
  align-items: center;
  font-size: 1.2rem;
  /* position: fixed; */

  z-index: 6;
}
.lang-icon {
  display: none;
}
.navbar-logo {
  position: absolute;
  left: 40px;
  top: 5px;
}
.navbar-logo img {
  width: 180px;
}
.max-width {
  display: flex;
  align-items: center;
}
.nav-menu {
  display: none;
}

.profile {
  list-style: none;
  text-align: center;

  justify-self: flex-end;

  position: relative;
  list-style: none;
  cursor: pointer;
}

.nav-link {
  color: #fff;
  list-style: none;
  text-decoration: none;
  align-items: center;
  display: flex;
  width: 100%;
  padding: 0.8rem 0.5rem;
  cursor: pointer;
  list-style: none;
  /* border-bottom: 1px solid #46b481; */
  border-bottom: 1px solid white;
}
.nav-link i {
  padding-right: 10px;
}
.nav-link-moblie {
  display: none;
}

/* .nav-link:hover {
  background-color: #55c28f;
  transform: scale(1.05);
  border-radius: 5px;
  transition: all 0.3s ease-out;
} */

.fa-bars {
  color: #fff;
}

.menu-icon {
  display: none;
}

.items {
  color: #fff;
  font-size: 20px;
  text-decoration: none;
  padding: 0.8rem 3rem;
  cursor: pointer;
}

.sub-item {
  flex-direction: column;
  position: absolute;
  align-items: baseline;
  right: -10px;
  top: -100%;
  opacity: 1;
  padding: 1rem -1rem;
  list-style: none;
  transition: all 0.5s ease;
}

.sub-item.active {
  background: #38c29493;
  align-items: baseline;
  top: 40px;
  opacity: 1;
  transition: all 0.5s ease-in-out;
  z-index: 1;
}

.sub-items {
  display: none;
  transition: all 0.5s ease;
}
.sub-items.active {
  align-items: baseline;

  display: table;
  transition: all 0.5s ease;
}

.fa-chevron-up,
.fa-chevron-down {
  color: #fff;
}
.sidebar-show {
  display: none;
}
.lang {
  margin: auto;
  color: #fff;
  font-size: 20px;
  margin-right: 2%;

  cursor: pointer;
}
.lang a {
  margin: 5px;
}
.lang a:hover {
  color: #05386b;
}
.lang a:active {
  color: #0a4b8b;
}
.lang i {
  margin-right: 5px;
  font-size: 18px;
}

.lang-sm {
  display: none;
}
@media screen and (max-width: 960px) {
  .NavbarItem {
    position: relative;
    width: 100%;
  }
  .lang-icon {
    display: block;
    position: absolute;
    top: -10px;
    right: 5px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
  .lang-sm {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    position: absolute;
    top: -100px;
    left: calc(100vw - 100px);
    opacity: 1;
    transition: all 0.5s ease;
  }
  .lang-sm.active {
    background: #61bbac;
    position: absolute;
    top: 60px;
    left: calc(100vw - 100px);
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 0;
  }
  .lang-sm li {
    color: #fff;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #10740d;
    list-style: none;
  }
  .lang-sm li:hover {
    background: #42b37a;
    transform: scale(1.04);
  }

  .lang {
    display: none;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 565px; */
    position: fixed;
    top: 60px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-transparent-blur {
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 60px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  .nav-menu.active {
    /* background: #99f2c8; */
    background-color: rgb(5, 56, 107);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-link {
    /* text-align: center; */
    padding: 2rem 20vw;
    width: 100%;
    display: table;
  }

  .sidebar-show {
    display: table;
    padding: 2rem 20vw;
    /* text-align: center; */
    text-decoration: none;
    color: white;
    width: 100%;
    /* border-bottom: 1px solid #46b481; */
    border-bottom: 1px solid white;
  }
  .sidebar-show i {
    padding-right: 15px;
  }
  .sidebar-show .item i {
    margin: 0 auto;
  }
  .navbar-logo {
    position: relative;
    margin: 0 auto;
    left: 0;
    top: 5px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: -0.5rem;
    left: 50px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    justify-self: start;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  /* .sidebar-show:hover {
    background: #fff;
    color: #57d878;
    transform: scale(1.02);
    transition: 250ms;
  } */

  /* .nav-link:hover {
    background: #fff;
    color: #57d878;
    transition: 250ms;
  } */

  Button {
    display: none;
  }
  .profile {
    display: none;
  }
}

.ms-ScrollablePane {
  background: linear-gradient(90deg, #2e332c, #2e2e2e);
}
.sidebar-label {
  font-family: "CSChatThaiUI", sans-serif;
  font-size: 20px;
  color: rgb(253, 253, 253);
  text-align: left;
  line-height: 1.6;
  padding-left: 10px;
}
div {
  font-family: "CSChatThaiUI", sans-serif;
}
.sidebar-notification {
  padding: 0 0.5rem;
  font-size: 16px;
  background-color: crimson;
  border-radius: 50%;
  position: relative;
  left: -20px;
  top: -20px;
  margin-right: -25px;
  color: white;
}
.sidebar-header-text {
  color: white;
  font-weight: 500;
  font-size: 22px;
  padding-left: 10px;
  -webkit-user-select: none;
          user-select: none;
}
.sidebar-header-container {
  cursor: pointer;
  padding: 30px 5% 25px 5%;
  justify-content: space-between;
}
.sidebar-dropdown-icon {
    color: white;
    font-size: 26px;
    -webkit-user-select: none;
            user-select: none;
}


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:'Montserrat', sans-serif;
}

.xlm label{
   border: 2px dashed rgb(194, 194, 194);
    height: 300px;
    width: 700px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
}
.xlm input{
    position: relative;
    padding-top: 50px;
}



  .xlm{
    border: 2px dashed rgb(194, 194, 194);
    height: 300px;
    width: 700px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: center;
  }
  .xlm p{
    font-size: 25px;
    margin-bottom: 10px;
    color: rgb(151, 151, 151);
    font-weight: 500;    
  }
  /* 
  button{
    border: 2px solid #5CDB95;
    color: #5CDB95;
    background-color: #fff;
    padding: 10px 10px;
    font-size: 18px;
    transition: all 0.3s ease;
  }
  button:hover{
    background-color: #5CDB95;
    color: #fff;
  }

 */

.containers-dsign {
  width: calc(100vw - 267px);
  overflow: hidden;
}
.up-btn {
  border: 1px solid #c4c4c4;
  margin-bottom: 0px;
  cursor: pointer;
  padding: 10px 25px;
  font-size: 20px;
  font-weight: 500;
  outline: none;
  border-radius: 5px;
  background: white !important;
  color: #000;
}
.up-btn:hover {
  background: #bebebe !important;
  color: rgb(0, 0, 0);
}
.dropzone-text {
  font-size: 25px;
  color: rgb(151, 151, 151);
  font-weight: 500;
  margin-top: -70px;
}
/* For all pages */
.all-page-container {
  background-color: gray;
  min-height: calc(100vh - 130px);
  max-width: calc(100vw - 250px);
}
.loading-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 120px;
  max-width: calc(100vw - 267px);
}

.columns-list-span {
  display: flex;
  align-items: center;
  height: 100%;
}

.pointer-cursor {
  cursor: pointer;
}

.dropzone-filter-header {
  width: 10%;
  padding: 5%;
  align-self: center;
}

.dropzone-filter-text {
  width: 90px;
  margin-top: 0;
  align-self: center;
  text-align: right;
}

.dropzone-filter-dropdown {
  width: 70px;
  margin: 0 10px;
  height: 30px;
  border-radius: 6px;
  align-self: center;
}

.form-label {
    font-size: larger;
    margin-top: 2%;
}
input {
    width: 100%;
    border: 1px solid;
    border-radius: 10px;
    padding: 2%;
    font-size: larger !important;
}
.forms {
    border: 2px dashed gray;
    padding: 5%;
    margin-top: 2.5%;
    border-radius: 10%;
    width: 50vw;
    align-self: center;
}
.divider {
    border: 1px solid gray;
    height: 0;
    width: 100%;
    margin: 5% 0;
}
.frame {
    border: 1px solid gray;
    padding: 3% 2%;
    display: grid;
    grid-template-columns: 25% 75%;
    -webkit-user-select: none;
            user-select: none;
}
.submit-buttons-frame {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 10vh;
}
.submit-button {
    cursor: "pointer";
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 15px;
}
textarea {
    width: 100%;
    border: 1px solid;
    border-radius: 10px;
    padding: 2%;
    font-size: larger !important;
    resize: none !important;
}
.icon {
    text-align: center;
    justify-content: center;
}
h1 {
    -webkit-user-select: none;
            user-select: none;
}
h3 {
    overflow: hidden;
}
#back {
    cursor: pointer;
}
.certificates-card{
    background: rgb(248, 248, 248);
    justify-content: center;
    width: 500px;
    padding: 25px;
    /* margin: 10px; */
    list-style: none;
    border-radius: 5px;
    box-shadow: 5px 5px 8px rgba(17, 17, 17, 0.137);
    
}
.certificates-delete-btn :hover {
    color: red;
}
.certificates-loading-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* min-height: calc(100vh - 130px); */
    margin-top: 50px;
    width: 500px;
    padding: 25px;
    /* max-width: calc(100vw - 267px); */
   /*  height: 100%;
    width: 100%;
    background-color: gray; */
    /* border-radius: 5px;
    box-shadow: 5px 5px 8px rgba(17, 17, 17, 0.137); */
}


.sidenav {
  height: 100%;
  width: 25%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ffffff;
  border-right: 1px solid black;
  overflow-x: hidden;
  padding-top: 60px;
}

.miniSidenav {
  height: 76px;
  width: 100px;
  position: fixed;
  z-index: 1;
  bottom: 20px;
  left: 20px;
  background-color: lightgray;
  border-right: 1px solid black;
  overflow-x: hidden;
  text-align: center;
  cursor: pointer;
}

center#minimize {
  font-size: 50px;
}

.miniside{
  display: grid;
  grid-template-columns: 5% 15% 60% auto;
  background-color: #ebebeb;
  width: 95%;
  max-height: 7.5vh;
  border-top: 1px solid rgb(189, 189, 189);
  border-right: 1px solid rgb(189, 189, 189);
  border-bottom: 1px solid rgb(189, 189, 189);
  border-left: 5px solid yellowgreen;
  padding: 10px 5px 10px 15px;
  margin: 10px;
  font-size: larger;
  cursor: pointer;
  align-items: center;
}

.signside{
  display: grid;
  grid-template-columns: 5% 75% auto;
  background-color: #ebebeb;
  width: 95%;
  max-height: 7.5vh;
  border-top: 1px solid rgb(189, 189, 189);
  border-right: 1px solid rgb(189, 189, 189);
  border-bottom: 1px solid rgb(189, 189, 189);
  border-left: 5px solid yellowgreen;
  -webkit-box-pack: center;
  padding: 10px 5px 10px 15px;
  margin: 10px;
  font-size: larger;
  cursor: pointer;
  align-items: center;
}

#stampside{
  display: grid;
  grid-template-columns: 5% 75% auto;
  background-color: #ebebeb;
  width: 95%;
  max-height: 7.5vh;
  border-top: 1px solid rgb(189, 189, 189);
  border-right: 1px solid rgb(189, 189, 189);
  border-bottom: 1px solid rgb(189, 189, 189);
  border-left: 5px solid yellowgreen;
  -webkit-box-pack: center;
  padding: 10px 5px 10px 15px;
  margin: 10px;
  font-size: larger;
  cursor: pointer;
  align-items: center;
}

#stampdiv{
  padding-left: 5%;
}

#imagediv{
  padding-left: 5%;
}

.minigrid{
  display: grid;
  grid-template-columns: auto 10%;
  align-items: center;
}

#export.export{
  width: 23.75%;
  height: auto;
  padding: 10px 5px 10px 15px;
  margin: 10px;
  font-size: larger;
  cursor: pointer;
  align-items: center;
  position: fixed;
  bottom: 20px;
}

.add{
  color: gray;
  font-weight: bold;
}

.popupbox{
  position: fixed;
  z-index: 10;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.transparentblur{
  z-index: 9;
  background-color : rgba(0, 0, 0, 0.4); 
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exportingbg{
  z-index: 9;
  top: 60px;
  left: 250px;
  width: 100%;
  height: 100%;
  position: absolute;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.largebox{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 10;
  background-color : white;
  box-sizing: content-box;
  position: relative;
}

.menu {
  background-color: lightgray;
  width: 200px;
  height: 500px;
}

.menutext{
  text-align: center;
  padding: 20px 100px 20px 100px;
  font-size: larger;
}

.menubutton{
  text-align: center;
  padding: 15px 30px 15px 30px;
  margin: 5px;
  font-size: larger;
  flex-grow: 2;
  cursor: pointer;
  border: 1px solid rgb(110, 110, 110);
}

.break {
  flex-basis: 100%;
  height: 0;
}

.minibox {
  background-color: #ebebeb;
  width: 180px;
  border: 1px solid black;
  padding: 10px 5px 10px 5px;
  margin: 10px;
  font-size: larger;
  cursor: pointer;
  z-index: 10;
  border-right: 1px solid black;
  overflow-x: hidden;
  text-align: center;
  cursor: pointer;
  flex: 40% 1;
}

#dropimage{
  padding: 24px;
}

#drawzone{
  padding: 20px;
}

#typebox{
  padding: 20px;
}

#signature-pad{
  background-color: lightgray;
  border: 1px solid darkslategrey;
  margin: 5px;
}

.signature-pad-slider{
  margin: 5px;
}

.signature-pad-buttons{
  display: grid;
  grid-template-columns: 15% auto 35% 15%;
  padding: 10px;
}

.colorPicker{
  cursor: pointer;
}

.textbox-pad-buttons{
  display: grid;
  grid-template-columns: auto 40%;
  padding: 10px;
}

.textbox-finished-pad-button{
  display: grid;
  grid-template-columns: auto 35%;
  padding: 10px;
}

.dropzoneimg{
  display: flex;
  width: 500px;
  height: 450px;
  flex-direction: column;
  border: 2px dashed rgb(134, 134, 134);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: center;
}

/* override cropper.css for round crop */
/* .cropper-view-box,
    .cropper-face {
      border-radius: 50%;
} */

#close-stamp-tab{
  text-align: right;
  padding: 10px;
  margin-bottom: -25px;
}

#image{
  display: block;
  max-width: 100%;
}

.crop-menu {
  text-align: right;
  padding-top: 30px;
}

#apply-crop{
  height: 50px;
  font-size: xx-large;
  cursor: pointer;
  width: 100%;
}

#crop-pad{
  padding: 50px;
  max-width: 500px;
}

.container.crop-pad {
  max-width: 100%;
  display: block;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0px;
  overflow: hidden;
}

#signtextbox{
  display: flex;
  white-space: nowrap;
  width: 500px;
  height: 300px;
  margin: auto;
  padding: 100px 0px;
  flex-direction: column;
  border: 2px solid rgb(134, 134, 134);
  font-size: 100px;
  overflow: hidden;
}

#textboxfont{
  height: 90%;
  font-size: 20px;
}

#deleteSign{
  display: flex;
  background-color: #ebebeb;
  cursor: pointer;
  align-items: center;
}

#imagediv{
  z-index: 999;
}

.export-loading-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 120px;
  max-width: calc(100vw - 267px);
}
.all-page-container .react-pdf__Page__canvas {
    margin: 0 auto;
    width: calc(100vw - 250px - 20%) !important;
    height: 100% !important;
}
.all-page-container .react-pdf__Document {
    overflow-y: hidden;
}
.Drop {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 20px;
}

.pdf-padding {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.button-padding {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.head i {
  padding-right: 10px;
}

.head h4 {
  color: gray;
}

.containers#lesspad {
  padding-top: 0px;
  overflow: hidden;
  width: calc(100vw - 267px);
}

.container.pdfscreen {
  position: fixed;
  width: 75%;
  height: 100%;
  right: 0;
}

.container.pdfscreenfull {
  position: fixed;
  width: 75%;
  height: 100%;
  right: 1%;
}

.card {
  background-color: whitesmoke;
  border: 1px solid black;
  overflow: scroll;
  position: relative;
  justify-content: right;
}

#pdfviewer {
  position: relative;
}

.padtop {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  padding-top: 0px;
  margin-top: 25px;
}

.dropzone {
  border: 2px dashed rgb(134, 134, 134);
  height: 350px;
  width: 1000px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: center;
}

.dropzone p {
  font-size: 25px;
  margin-top: 150px;
  color: rgb(151, 151, 151);
  font-weight: 500;
  position: absolute;
}

.dropzone button {
  padding: 10px 25px;
  font-size: 20px;
  font-weight: 500;
  outline: none;
  border-radius: 5px;
  background: #fff;
  color: #000;
}

.up-btn {
  border: 1px solid #c4c4c4;
  background: #c4c4c4;
  color: rgb(53, 52, 107);
  margin-bottom: 90px;
  cursor: pointer;
}

#zoom {
  font-size: medium;
}

#textOptionBox {
  border: 1px solid black;
  background-color: white;
  box-shadow: 1px 1px 5px black;
  padding: 5px;
  position: absolute;
  cursor: pointer;
}

#signOptionBox {
  border: 1px solid black;
  background-color: white;
  box-shadow: 1px 1px 5px black;
  padding: 5px;
  position: absolute;
  cursor: pointer;
}

#colorBox {
  border: 1px solid black;
  background-color: white;
  box-shadow: 1px 1px 5px black;
  padding: 5px;
  position: absolute;
  cursor: pointer;
}

.optionBox {
  position: absolute;
  cursor: pointer;
}

.noselect {
  -webkit-user-select: none;
          user-select: none;
}

.exporting-text {
  font-size: xx-large;
  font-weight: bolder;
  color: black;
}

.pointer-cursor {
  cursor: pointer;
}

.columns-list-span {
  display: flex;
  align-items: center;
  height: 100%;
}

.all-page-container {
  background-color: gray;
  min-height: calc(100vh - 130px);
  max-width: calc(100vw - 250px);
}

.dropzone-filter-header {
  width: 10%;
  padding: 5%;
  align-self: center;
}

.dropzone-filter-text {
  width: 90px;
  margin-top: 0;
  align-self: center;
  text-align: right;
}

.dropzone-filter-dropdown {
  width: 70px;
  margin: 0 10px;
  height: 30px;
  border-radius: 6px;
  align-self: center;
}

.dropzone-loading-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 120px;
  max-width: calc(100vw - 267px);
}

.mobile-sidenav {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ffffff;
  border-right: 1px solid black;
  overflow-x: hidden;
  padding-top: 60px;
}

.miniSidenav {
  height: 76px;
  width: 100px;
  position: fixed;
  z-index: 1;
  bottom: 20px;
  left: 20px;
  background-color: lightgray;
  border-right: 1px solid black;
  overflow-x: hidden;
  text-align: center;
  cursor: pointer;
}

center#minimize {
  font-size: 50px;
}

.mobile-miniside{
  display: grid;
  grid-template-columns: 5% 15% 60% auto;
  background-color: #ebebeb;
  width: 95%;
  height: 10vh;
  border-top: 1px solid rgb(189, 189, 189);
  border-right: 1px solid rgb(189, 189, 189);
  border-bottom: 1px solid rgb(189, 189, 189);
  border-left: 5px solid yellowgreen;
  padding: 10px 5px 10px 15px;
  margin: 10px;
  font-size: larger;
  cursor: pointer;
  align-items: center;
}

.mobile-signside{
  display: grid;
  grid-template-columns: 5% 75% auto;
  background-color: #ebebeb;
  width: 95%;
  height: 10vh;
  border-top: 1px solid rgb(189, 189, 189);
  border-right: 1px solid rgb(189, 189, 189);
  border-bottom: 1px solid rgb(189, 189, 189);
  border-left: 5px solid yellowgreen;
  -webkit-box-pack: center;
  padding: 10px 5px 10px 15px;
  margin: 10px;
  font-size: larger;
  cursor: pointer;
  align-items: center;
}

#mobile-stampside{
  display: grid;
  grid-template-columns: 5% 75% auto;
  background-color: #ebebeb;
  width: 95%;
  height: 10vh;
  border-top: 1px solid rgb(189, 189, 189);
  border-right: 1px solid rgb(189, 189, 189);
  border-bottom: 1px solid rgb(189, 189, 189);
  border-left: 5px solid yellowgreen;
  -webkit-box-pack: center;
  padding: 10px 5px 10px 15px;
  margin: 10px;
  font-size: larger;
  cursor: pointer;
  align-items: center;
}

.minigrid{
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
}

.mobile-miniside-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 10vh;
  padding: 10px 5px;
}

.mobile-export {
  width: 45%;
  height: auto;
  padding: 10px;
  margin: 10px;
  font-size: larger;
  cursor: pointer;
  align-items: center;
}

.mobile-sidenav-hide {
  width: 45%;
  height: auto;
  padding: 10px;
  margin: 10px;
  font-size: larger;
  cursor: pointer;
  align-items: center;
}

.mobile-sidenav-show {
  display: flex;
  width: 75px;
  height: 75px;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  border: 1px solid black;
  z-index: 1;
}

.add{
  color: gray;
  font-weight: bold;
}

.popupbox{
  position: fixed;
  z-index: 10;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.transparentblur{
  z-index: 9;
  background-color : rgba(0, 0, 0, 0.4); 
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-exportingbg{
  z-index: 9;
  width: 100%;
  height: 100%;
  top: 60px;
  position: absolute;
}

.mobile-largebox{
  display: flex;
  flex-wrap: wrap;
  width: 90vw;
  justify-content: center;
  z-index: 10;
  background-color : white;
  box-sizing: content-box;
  position: relative;
}

.mobile-menu {
  background-color: lightgray;
  width: 90vw;
  height: 10vh;
  display: flex;
}

.menutext{
  text-align: center;
  padding: 20px 100px 20px 100px;
  font-size: larger;
}

.menubutton{
  text-align: center;
  padding: 15px 30px 15px 30px;
  margin: 5px;
  font-size: larger;
  flex-grow: 2;
  cursor: pointer;
  border: 1px solid rgb(110, 110, 110);
}

.break {
  flex-basis: 100%;
  height: 0;
}

.mobile-minibox {
  background-color: #ebebeb;
  width: 20%;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  font-size: larger;
  cursor: pointer;
  z-index: 10;
  border-right: 1px solid black;
  overflow-x: hidden;
  text-align: center;
  cursor: pointer;
  flex: 40% 1;
}

#mobile-dropimage{
  padding: 5vw;
}

.mobile-drawzone{
  padding: 5vw;
}

.mobile-typebox{
  padding: 5vw;
}

#mobile-signature-pad{
  background-color: lightgray;
  border: 1px solid darkslategrey;
  height: 30vh;
  width: 80vw;
}

.mobile-signature-pad-slider{
  margin: 5px;
}

.mobile-signature-pad-buttons{
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.mobile-signature-pad-button{
  width: 15vw;
  height: 15vw;
}

.colorPicker{
  cursor: pointer;
}

.mobile-textbox-pad-buttons{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-textbox-color-pad{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  justify-self: start;
}

.mobile-textbox-finished-pad-button{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}

.mobile-dropzoneimg {
  display: flex;
  width: 80vw;
  height: 50vh;
  flex-direction: column;
  border: 2px dashed rgb(134, 134, 134);
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: center;
}

.mobile-dropzone-text {
  text-align: center;
  padding: 20px;
}

/* override cropper.css for round crop */
/* .cropper-view-box,
    .cropper-face {
      border-radius: 50%;
} */

#close-stamp-tab{
  text-align: right;
  padding: 10px;
  margin-bottom: -25px;
}

#image{
  display: block;
  max-width: 100%;
}

.crop-menu {
  text-align: right;
  padding-top: 30px;
}

#mobile-apply-crop{
  height: 50px;
  width: 100%;
  font-size: xx-large;
}

#crop-pad{
  padding: 50px;
  max-width: 500px;
}

.container.crop-pad {
  max-width: 100%;
  display: block;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0px;
  overflow: hidden;
}

#mobile-signtextbox{
  display: flex;
  white-space: nowrap;
  width: 80vw;
  height: 30vh;
  margin: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid rgb(134, 134, 134);
  font-size: 50px;
  overflow: hidden;
}

#textboxfont{
  height: 90%;
  font-size: large;
}

#deleteSign{
  display: flex;
  background-color: #ebebeb;
  cursor: pointer;
  align-items: center;
}

#imagediv{
  z-index: 999;
}

.mobile-export-loading-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 120px;
  max-width: calc(100vw - 267px);
}
.mobile-all-page-container .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
}
.mobile-all-page-container .react-pdf__Document {
    overflow-y: scroll;
}
.Drop {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 20px;
}

.mobile-pdf-padding {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  padding: 5px;
}

.button-padding {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.head i {
  padding-right: 10px;
}

.head h4 {
  color: gray;
}

/* .containers#lesspad {
  display: block;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  overflow: hidden;
} */

.container.pdfscreen {
  position: fixed;
  width: 75%;
  height: 100%;
  right: 0;
}

.container.pdfscreenfull {
  position: fixed;
  width: 75%;
  height: 100%;
  right: 1%;
}

.mobile-card {
  background-color: whitesmoke;
  border: 1px solid black;
  overflow: scroll;
  position: relative;
  justify-content: right;
}

#pdfviewer {
  position: relative;
}

#padtop {
  padding-top: 0px;
  margin-top: 25px;
}

.padtop10 {
  padding-top: 10%;
}

.dropzone {
  border: 2px dashed rgb(134, 134, 134);
  height: 350px;
  width: 1000px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: center;
}

.dropzone p {
  font-size: 25px;
  margin-top: 150px;
  color: rgb(151, 151, 151);
  font-weight: 500;
  position: absolute;
}

.dropzone button {
  padding: 10px 25px;
  font-size: 20px;
  font-weight: 500;
  outline: none;
  border-radius: 5px;
  background: #fff;
  color: #000;
}

.up-btn {
  border: 1px solid #c4c4c4;
  background: #c4c4c4;
  color: rgb(53, 52, 107);
  margin-bottom: 90px;
  cursor: pointer;
}

.mobile-pdf-header-left {
  text-align: left;
  display: flex; 
  align-items: center;
}

.mobile-pdf-header-center {
  text-align: center;
  display: flex; 
  align-items: center;
}

.mobile-pdf-header-right {
  text-align: right;
  display: flex; 
  align-items: center;
}

.mobile-zoom {
  width: 100%;
  border-radius: 5px;
  background-color: white;
  font-size: medium;
}

#textOptionBox {
  border: 1px solid black;
  background-color: white;
  box-shadow: 1px 1px 5px black;
  padding: 5px;
  position: absolute;
  cursor: pointer;
}

#signOptionBox {
  border: 1px solid black;
  background-color: white;
  box-shadow: 1px 1px 5px black;
  padding: 5px;
  position: absolute;
  cursor: pointer;
}

#colorBox {
  border: 1px solid black;
  background-color: white;
  box-shadow: 1px 1px 5px black;
  padding: 5px;
  position: absolute;
  cursor: pointer;
}

.optionBox {
  position: absolute;
  cursor: pointer;
}

.noselect {
  -webkit-user-select: none;
          user-select: none;
}

.exporting-text {
  font-size: xx-large;
  font-weight: bolder;
  color: black;
}

.bottom-sheet {
  position: absolute;
  background-color : white;
  bottom: 0;
  left: 0;
  width: 100%;
}

.transparentbg{
  z-index: 9;
  background-color : rgba(0, 0, 0, 0.4); 
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
}

.mobile-all-page-container {
  background-color: gray;
  display: flex;
  justify-content: center;
  align-items: top;
  min-height: calc(100vh);
  max-width: calc(100vw);
}

.mobile-filter-header {
  width: 30vw;
  padding: 5%;
  font-size: larger;
  font-family: 'CSChatThaiUI', sans-serif;
  align-self: center;
}

.mobile-filter-text {
  width: 15vw;
  margin-top: 0;
  margin-right: 2vw;
  font-size: larger;
  font-family: 'CSChatThaiUI', sans-serif;
  align-self: center;
  text-align: right;
}

.mobile-filter-dropdown {
  width: 20vw;
  height: 60%;
  margin-right:  5%;
  border-radius: 10px;
  font-size: larger;
  align-self: center;
}

.mobile-popup {
  background-color: white;
  position: fixed;
  top: 35vh;
  left: 10vw;
  width: 80vw;
  height: 30vh;
  border-radius: 20px;
}

.mobile-popup-close {
  position: relative;
  top: 5%;
  left: 90%;
  width: 5%;
  height: 10%;
}

.mobile-popup-text {
  padding: 5vh 0;
  height: 12vh;
}

.mobile-popup-button {
  width: 100%;
  height: 10.5vh;
  padding-left: 5%;
  padding-right: 5%;
  display: grid;
  grid-template-columns: 50% 50%;
}

.resizable {
  touch-action: none;
}

.drag-drop {
  touch-action: none;
}

.MoreVertical {
  display: flex;
  justify-items: center;
  align-content: center;
}

.mobile-nodata {
  padding-top: 1vh;
  font-size: larger;
}



.mobile-label {
    font-size: larger;
    margin-top: 2%;
}
.mobile-input {
    width: 100%;
    border: 1px solid;
    border-radius: 10px;
    padding: 2%;
    font-size: larger !important;
}
.mobile-forms {
    padding: 5%;
    margin: 0%;
    width: 100vw;
}
.mobile-divider {
    border: 1px solid gray;
    height: 0;
    width: 100%;
    margin: 5% 0;
}
.mobile-frame {
    border: 1px solid gray;
    padding: 3% 2%;
    display: grid;
    grid-template-columns: 25% 75%;
    -webkit-user-select: none;
            user-select: none;
}
.mobile-textarea {
    width: 100%;
    border: 1px solid;
    border-radius: 10px;
    padding: 2%;
    font-size: larger !important;
    resize: none !important;
}
.mobile-icon {
    text-align: center;
    justify-content: center;
}
.mobile-h1 {
    -webkit-user-select: none;
            user-select: none;
    vertical-align: baseline;
}
.mobile-h3 {
    overflow: hidden;
}
.mobile-back {
    margin-right: 0.5rem;
    cursor: pointer;
}
.mobile-send-nav {
    display: flex;
    justify-content: space-between;
}
.mobile-send-header {
    display: inline-flex;
    align-items: center;
}
.mobile-send {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: blue;
}
.mobile-send-icon {
    margin-left: 0.5rem;
}
.mobile-upload {
    position: absolute;
    bottom: 5vw;
    right: calc(50vw - 50px);
    z-index: 2;
    background-color: #05386B;
    color: white;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mobile-upload-label {
    font-size: 100px;
}
.mobile-uploadfile {
    display: none;
}
.mobile-certificates-card{
    background: rgb(248, 248, 248);
    justify-content: center;
    width: 80vw;
    padding: 25px;
    list-style: none;
    border-radius: 5px;
    box-shadow: 5px 5px 8px rgba(17, 17, 17, 0.137);
    margin-top: 5% !important;  
}
.certificates-delete-btn :hover {
    color: red;
}
.certificates-loading-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 50px;
    width: 500px;
    padding: 25px;
}


.contact-us {
  display: grid;
  grid-template-areas:
    "head"
    "qr"
    "desc";
  grid-template-rows: 1fr 150px 1fr;
  grid-template-columns: 100%;
  grid-gap: 7.5%;
  margin: 5vh 10vw;
}

.contact-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
}

.contact-header {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: head;
}

.contact-desc {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 1.5;
  grid-area: desc;
}

.contact-office-hrs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.qr {
  border: 1px solid black;
  width: 150px;
  height: 150px;
}

.qr-container {
  display: flex;
  justify-content: center;
  align-items: center;

  grid-area: qr;
}

