/* 
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@200&display=swap');
*{
  font-family: 'Prompt', sans-serif;
}
 */
.NavbarItem {
  background: linear-gradient(90deg, #05386b 50%, #99f2c8);
  height: 60px;
  width: 100vw;
  display: flex;

  padding-left: 70px;
  padding-right: 90px;
  align-items: center;
  font-size: 1.2rem;
  /* position: fixed; */

  z-index: 6;
}
.lang-icon {
  display: none;
}
.navbar-logo {
  position: absolute;
  left: 40px;
  top: 5px;
}
.navbar-logo img {
  width: 180px;
}
.max-width {
  display: flex;
  align-items: center;
}
.nav-menu {
  display: none;
}

.profile {
  list-style: none;
  text-align: center;

  justify-self: flex-end;

  position: relative;
  list-style: none;
  cursor: pointer;
}

.nav-link {
  color: #fff;
  list-style: none;
  text-decoration: none;
  align-items: center;
  display: flex;
  width: 100%;
  padding: 0.8rem 0.5rem;
  cursor: pointer;
  list-style: none;
  /* border-bottom: 1px solid #46b481; */
  border-bottom: 1px solid white;
}
.nav-link i {
  padding-right: 10px;
}
.nav-link-moblie {
  display: none;
}

/* .nav-link:hover {
  background-color: #55c28f;
  transform: scale(1.05);
  border-radius: 5px;
  transition: all 0.3s ease-out;
} */

.fa-bars {
  color: #fff;
}

.menu-icon {
  display: none;
}

.items {
  color: #fff;
  font-size: 20px;
  text-decoration: none;
  padding: 0.8rem 3rem;
  cursor: pointer;
}

.sub-item {
  flex-direction: column;
  position: absolute;
  align-items: baseline;
  right: -10px;
  top: -100%;
  opacity: 1;
  padding: 1rem -1rem;
  list-style: none;
  transition: all 0.5s ease;
}

.sub-item.active {
  background: #38c29493;
  align-items: baseline;
  top: 40px;
  opacity: 1;
  transition: all 0.5s ease-in-out;
  z-index: 1;
}

.sub-items {
  display: none;
  transition: all 0.5s ease;
}
.sub-items.active {
  align-items: baseline;

  display: table;
  transition: all 0.5s ease;
}

.fa-chevron-up,
.fa-chevron-down {
  color: #fff;
}
.sidebar-show {
  display: none;
}
.lang {
  margin: auto;
  color: #fff;
  font-size: 20px;
  margin-right: 2%;

  cursor: pointer;
}
.lang a {
  margin: 5px;
}
.lang a:hover {
  color: #05386b;
}
.lang a:active {
  color: #0a4b8b;
}
.lang i {
  margin-right: 5px;
  font-size: 18px;
}

.lang-sm {
  display: none;
}
@media screen and (max-width: 960px) {
  .NavbarItem {
    position: relative;
    width: 100%;
  }
  .lang-icon {
    display: block;
    position: absolute;
    top: -10px;
    right: 5px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
  .lang-sm {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    position: absolute;
    top: -100px;
    left: calc(100vw - 100px);
    opacity: 1;
    transition: all 0.5s ease;
  }
  .lang-sm.active {
    background: #61bbac;
    position: absolute;
    top: 60px;
    left: calc(100vw - 100px);
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 0;
  }
  .lang-sm li {
    color: #fff;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #10740d;
    list-style: none;
  }
  .lang-sm li:hover {
    background: #42b37a;
    transform: scale(1.04);
  }

  .lang {
    display: none;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 565px; */
    position: fixed;
    top: 60px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-transparent-blur {
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 60px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  .nav-menu.active {
    /* background: #99f2c8; */
    background-color: rgb(5, 56, 107);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-link {
    /* text-align: center; */
    padding: 2rem 20vw;
    width: 100%;
    display: table;
  }

  .sidebar-show {
    display: table;
    padding: 2rem 20vw;
    /* text-align: center; */
    text-decoration: none;
    color: white;
    width: 100%;
    /* border-bottom: 1px solid #46b481; */
    border-bottom: 1px solid white;
  }
  .sidebar-show i {
    padding-right: 15px;
  }
  .sidebar-show .item i {
    margin: 0 auto;
  }
  .navbar-logo {
    position: relative;
    margin: 0 auto;
    left: 0;
    top: 5px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: -0.5rem;
    left: 50px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    justify-self: start;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  /* .sidebar-show:hover {
    background: #fff;
    color: #57d878;
    transform: scale(1.02);
    transition: 250ms;
  } */

  /* .nav-link:hover {
    background: #fff;
    color: #57d878;
    transition: 250ms;
  } */

  Button {
    display: none;
  }
  .profile {
    display: none;
  }
}
