.certificates-card{
    background: rgb(248, 248, 248);
    justify-content: center;
    width: 500px;
    padding: 25px;
    /* margin: 10px; */
    list-style: none;
    border-radius: 5px;
    box-shadow: 5px 5px 8px rgba(17, 17, 17, 0.137);
    
}
.certificates-delete-btn :hover {
    color: red;
}
.certificates-loading-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* min-height: calc(100vh - 130px); */
    margin-top: 50px;
    width: 500px;
    padding: 25px;
    /* max-width: calc(100vw - 267px); */
   /*  height: 100%;
    width: 100%;
    background-color: gray; */
    /* border-radius: 5px;
    box-shadow: 5px 5px 8px rgba(17, 17, 17, 0.137); */
}

