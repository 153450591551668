.containers-dsign {
  width: calc(100vw - 267px);
  overflow: hidden;
}
.up-btn {
  border: 1px solid #c4c4c4;
  margin-bottom: 0px;
  cursor: pointer;
  padding: 10px 25px;
  font-size: 20px;
  font-weight: 500;
  outline: none;
  border-radius: 5px;
  background: white !important;
  color: #000;
}
.up-btn:hover {
  background: #bebebe !important;
  color: rgb(0, 0, 0);
}
.dropzone-text {
  font-size: 25px;
  color: rgb(151, 151, 151);
  font-weight: 500;
  margin-top: -70px;
}
/* For all pages */
.all-page-container {
  background-color: gray;
  min-height: calc(100vh - 130px);
  max-width: calc(100vw - 250px);
}
.loading-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 120px;
  max-width: calc(100vw - 267px);
}

.columns-list-span {
  display: flex;
  align-items: center;
  height: 100%;
}

.pointer-cursor {
  cursor: pointer;
}

.dropzone-filter-header {
  width: 10%;
  padding: 5%;
  align-self: center;
}

.dropzone-filter-text {
  width: 90px;
  margin-top: 0;
  align-self: center;
  text-align: right;
}

.dropzone-filter-dropdown {
  width: 70px;
  margin: 0 10px;
  height: 30px;
  border-radius: 6px;
  align-self: center;
}
