.form-label {
    font-size: larger;
    margin-top: 2%;
}
input {
    width: 100%;
    border: 1px solid;
    border-radius: 10px;
    padding: 2%;
    font-size: larger !important;
}
.forms {
    border: 2px dashed gray;
    padding: 5%;
    margin-top: 2.5%;
    border-radius: 10%;
    width: 50vw;
    align-self: center;
}
.divider {
    border: 1px solid gray;
    height: 0;
    width: 100%;
    margin: 5% 0;
}
.frame {
    border: 1px solid gray;
    padding: 3% 2%;
    display: grid;
    grid-template-columns: 25% 75%;
    user-select: none;
}
.submit-buttons-frame {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 10vh;
}
.submit-button {
    cursor: "pointer";
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 15px;
}
textarea {
    width: 100%;
    border: 1px solid;
    border-radius: 10px;
    padding: 2%;
    font-size: larger !important;
    resize: none !important;
}
.icon {
    text-align: center;
    justify-content: center;
}
h1 {
    user-select: none;
}
h3 {
    overflow: hidden;
}
#back {
    cursor: pointer;
}