.ms-ScrollablePane {
  background: linear-gradient(90deg, #2e332c, #2e2e2e);
}
.sidebar-label {
  font-family: "CSChatThaiUI", sans-serif;
  font-size: 20px;
  color: rgb(253, 253, 253);
  text-align: left;
  line-height: 1.6;
  padding-left: 10px;
}
div {
  font-family: "CSChatThaiUI", sans-serif;
}
.sidebar-notification {
  padding: 0 0.5rem;
  font-size: 16px;
  background-color: crimson;
  border-radius: 50%;
  position: relative;
  left: -20px;
  top: -20px;
  margin-right: -25px;
  color: white;
}
.sidebar-header-text {
  color: white;
  font-weight: 500;
  font-size: 22px;
  padding-left: 10px;
  user-select: none;
}
.sidebar-header-container {
  cursor: pointer;
  padding: 30px 5% 25px 5%;
  justify-content: space-between;
}
.sidebar-dropdown-icon {
    color: white;
    font-size: 26px;
    user-select: none;
}

