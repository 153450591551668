.sidenav {
  height: 100%;
  width: 25%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ffffff;
  border-right: 1px solid black;
  overflow-x: hidden;
  padding-top: 60px;
}

.miniSidenav {
  height: 76px;
  width: 100px;
  position: fixed;
  z-index: 1;
  bottom: 20px;
  left: 20px;
  background-color: lightgray;
  border-right: 1px solid black;
  overflow-x: hidden;
  text-align: center;
  cursor: pointer;
}

center#minimize {
  font-size: 50px;
}

.miniside{
  display: grid;
  grid-template-columns: 5% 15% 60% auto;
  background-color: #ebebeb;
  width: 95%;
  max-height: 7.5vh;
  border-top: 1px solid rgb(189, 189, 189);
  border-right: 1px solid rgb(189, 189, 189);
  border-bottom: 1px solid rgb(189, 189, 189);
  border-left: 5px solid yellowgreen;
  padding: 10px 5px 10px 15px;
  margin: 10px;
  font-size: larger;
  cursor: pointer;
  align-items: center;
}

.signside{
  display: grid;
  grid-template-columns: 5% 75% auto;
  background-color: #ebebeb;
  width: 95%;
  max-height: 7.5vh;
  border-top: 1px solid rgb(189, 189, 189);
  border-right: 1px solid rgb(189, 189, 189);
  border-bottom: 1px solid rgb(189, 189, 189);
  border-left: 5px solid yellowgreen;
  -webkit-box-pack: center;
  padding: 10px 5px 10px 15px;
  margin: 10px;
  font-size: larger;
  cursor: pointer;
  align-items: center;
}

#stampside{
  display: grid;
  grid-template-columns: 5% 75% auto;
  background-color: #ebebeb;
  width: 95%;
  max-height: 7.5vh;
  border-top: 1px solid rgb(189, 189, 189);
  border-right: 1px solid rgb(189, 189, 189);
  border-bottom: 1px solid rgb(189, 189, 189);
  border-left: 5px solid yellowgreen;
  -webkit-box-pack: center;
  padding: 10px 5px 10px 15px;
  margin: 10px;
  font-size: larger;
  cursor: pointer;
  align-items: center;
}

#stampdiv{
  padding-left: 5%;
}

#imagediv{
  padding-left: 5%;
}

.minigrid{
  display: grid;
  grid-template-columns: auto 10%;
  align-items: center;
}

#export.export{
  width: 23.75%;
  height: auto;
  padding: 10px 5px 10px 15px;
  margin: 10px;
  font-size: larger;
  cursor: pointer;
  align-items: center;
  position: fixed;
  bottom: 20px;
}

.add{
  color: gray;
  font-weight: bold;
}

.popupbox{
  position: fixed;
  z-index: 10;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.transparentblur{
  z-index: 9;
  background-color : rgba(0, 0, 0, 0.4); 
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exportingbg{
  z-index: 9;
  top: 60px;
  left: 250px;
  width: 100%;
  height: 100%;
  position: absolute;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.largebox{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 10;
  background-color : white;
  box-sizing: content-box;
  position: relative;
}

.menu {
  background-color: lightgray;
  width: 200px;
  height: 500px;
}

.menutext{
  text-align: center;
  padding: 20px 100px 20px 100px;
  font-size: larger;
}

.menubutton{
  text-align: center;
  padding: 15px 30px 15px 30px;
  margin: 5px;
  font-size: larger;
  flex-grow: 2;
  cursor: pointer;
  border: 1px solid rgb(110, 110, 110);
}

.break {
  flex-basis: 100%;
  height: 0;
}

.minibox {
  background-color: #ebebeb;
  width: 180px;
  border: 1px solid black;
  padding: 10px 5px 10px 5px;
  margin: 10px;
  font-size: larger;
  cursor: pointer;
  z-index: 10;
  border-right: 1px solid black;
  overflow-x: hidden;
  text-align: center;
  cursor: pointer;
  flex: 40%;
}

#dropimage{
  padding: 24px;
}

#drawzone{
  padding: 20px;
}

#typebox{
  padding: 20px;
}

#signature-pad{
  background-color: lightgray;
  border: 1px solid darkslategrey;
  margin: 5px;
}

.signature-pad-slider{
  margin: 5px;
}

.signature-pad-buttons{
  display: grid;
  grid-template-columns: 15% auto 35% 15%;
  padding: 10px;
}

.colorPicker{
  cursor: pointer;
}

.textbox-pad-buttons{
  display: grid;
  grid-template-columns: auto 40%;
  padding: 10px;
}

.textbox-finished-pad-button{
  display: grid;
  grid-template-columns: auto 35%;
  padding: 10px;
}

.dropzoneimg{
  display: flex;
  width: 500px;
  height: 450px;
  flex-direction: column;
  border: 2px dashed rgb(134, 134, 134);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: center;
}

/* override cropper.css for round crop */
/* .cropper-view-box,
    .cropper-face {
      border-radius: 50%;
} */

#close-stamp-tab{
  text-align: right;
  padding: 10px;
  margin-bottom: -25px;
}

#image{
  display: block;
  max-width: 100%;
}

.crop-menu {
  text-align: right;
  padding-top: 30px;
}

#apply-crop{
  height: 50px;
  font-size: xx-large;
  cursor: pointer;
  width: 100%;
}

#crop-pad{
  padding: 50px;
  max-width: 500px;
}

.container.crop-pad {
  max-width: 100%;
  display: block;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0px;
  overflow: hidden;
}

#signtextbox{
  display: flex;
  white-space: nowrap;
  width: 500px;
  height: 300px;
  margin: auto;
  padding: 100px 0px;
  flex-direction: column;
  border: 2px solid rgb(134, 134, 134);
  font-size: 100px;
  overflow: hidden;
}

#textboxfont{
  height: 90%;
  font-size: 20px;
}

#deleteSign{
  display: flex;
  background-color: #ebebeb;
  cursor: pointer;
  align-items: center;
}

#imagediv{
  z-index: 999;
}

.export-loading-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 120px;
  max-width: calc(100vw - 267px);
}